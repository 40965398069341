import colors from 'Components/colors.scss'
import styled from 'styled-components'

export const IconError = styled.span`
  &&& {
    font-size: 30px;
    color: ${colors.red};
  }
`

export const Wrapper = styled.div`
  && {
    max-width: 760px;
    padding: 0;
  }

  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;

  .filter-holder {
    width: 50%;
    margin-bottom: 10px;
    padding: 5px;
  }

  .filter-holder label:first-child {
    max-width: 480px;
    margin-bottom: 10px;
  }

  #sds-filter label:first-child {
    margin-bottom: 30px;
  }

  .filter-holder > div > div {
    height: 260px;
    overflow: auto;
    background-color: #363643;
    padding: 5px;
  }

  .filter-holder .pi-search {
    top: 12px;
  }
`
