import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { PageConstraint, PageHeader, TableContainer } from 'Components/layout'
import { Button } from "Components/input";
import { Modal, ToolPanel, DataTable } from "Components/readonly";
import modalContentConfirm from 'Components/combined/modalContentComponents/modalContentConfirm'
import CreateQrModal from './components/CreateQrModal'
import ReadQrModal from './components/ReadQrModal'
import useModalController from 'Hooks/useModalController'
import { Column } from "Components/readonly";
import useGetData from "Hooks/useGetData";
import axios from "axios";

const QrCodesPage = () => {
  const intl = useIntl()
  const [isLoading, qrCodeList, setQrCodeList] = useGetData('/rapi/qrCodes', [], [])
  const [modalControllerEditQrCode] = useModalController({title: intl.formatMessage({id: 'qr-codes.title-single'})})
  const [modalControllerReadQrCode] = useModalController({title: intl.formatMessage({id: 'qr-codes.title-single'})})
  const [selectedData, setSelectedData] = useState({});
  const [hideProductModalController] = useModalController({ title: intl.formatMessage({ id: 'common.delete' }) })
  /**
   * Delete QR code from the active select choices
   * @returns {Promise<void>}
   */
  const deleteQrCode = async (row) => {
    try {
      const response = await axios.delete('/rapi/qrCodes/' + row.id);
      setQrCodeList(response.data);
    } catch (error) {
      console.error(error.response.data.message);
    }
  };

  /**
   * Will filter structure of the get data for table list
   * @param list
   * @returns {*[]}
   */
  const filteredList = function (list) {
    let data = [];
    list.forEach((row, key) => {
      let products = [];
      row.pivot.forEach((obj) => products.push(obj?.sds?.[0]?.user_language_variant?.[0]?.name ?? "NaN"));
      data.push({
        increment: (key + 1),
        id: row.id,
        location_id: row?.location?.id,
        location_name: (row.location_name ?? intl.formatMessage({id: 'qr-codes.no-location'})),
        location: (row.location_name ?? ""),
        products: `${(products.length > 0) ? products.join(", ") + ' (' + products.length + ')' : intl.formatMessage({id: 'qr-codes.all-products'})}`,
        productIds: row.products,
        created_at: row.created_at
      });
    });
    return data;
  }

  /**
   * Will open modal for edit QR-Code
   * @param event
   */
  const editQrCode = (event) => {
    setSelectedData(event.data)
    modalControllerEditQrCode.open()
  }

  /**
   * Will create a custom open edit modal button for table column
   * @param row
   * @returns {JSX.Element}
   */
  const showQrCodeModalBtn = (row) => (
    <Button
      primary
      label={intl.formatMessage({id: 'qr-codes.show-code'})}
      onClick={(event) => {
        event.stopPropagation();
        setSelectedData(row)
        modalControllerReadQrCode.open()
      }}
    />
  );

  /**
   * Will create a custom delete button for table column
   * @param row
   * @returns {JSX.Element}
   */
  const deleteQrCodeBtn = (row) => (
    <Button
      primary
      icon="pi pi-trash"
      onClick={(event) => {
        event.stopPropagation();
        hideProductModalController.open({ data: row });
      }}
    />
  );

  return (
    <>
      <PageConstraint>
        <Modal
          modalController={modalControllerEditQrCode}
          modalData={selectedData}
          setQrCodeList={setQrCodeList}
          ContentComponent={CreateQrModal}
        />
        <Modal
          modalController={modalControllerReadQrCode}
          modalData={selectedData}
          ContentComponent={ReadQrModal}
        />
        <Modal
          modalController={hideProductModalController}
          ContentComponent={modalContentConfirm}
          contentTextId="qr-codes.confirm-delete"
          confirmTextId="qr-codes.confirm"
          onConfirm={deleteQrCode}
        />
        <PageHeader>

          <ToolPanel>
            <h1>
              <FormattedMessage id="qr-codes.title"/>
            </h1>
            <Button
              primary
              label={intl.formatMessage({id: 'qr-codes.create-qr-code'})}
              onClick={() => {
                setSelectedData({})
                modalControllerEditQrCode.open()
              }}
            />
          </ToolPanel>
        </PageHeader>
        <TableContainer className="fix-ie-table-width">
          {qrCodeList && (
            <DataTable
              responsive={true}
              className="clickable-rows"
              value={filteredList(qrCodeList)}
              loading={isLoading}
              onRowClick={editQrCode}
            >
              <Column style={{width: '60px'}} key="increment" field="increment" header="#" sortable={true}/>
              <Column style={{width: '20%'}} key="location_name" field="location_name" header={intl.formatMessage({id: 'common.location'})} sortable={true}/>
              <Column key="products" field="products" header={intl.formatMessage({id: 'chemicals.product'})} sortable={false}/>
              <Column style={{width: '14%'}} key="created_at" field="created_at" header={intl.formatMessage({id: 'sds.issue-date'})} sortable={true}/>
              <Column
                style={{width: '150px', textAlign: "center"}}
                key="qr_code"
                field="qr_code"
                body={showQrCodeModalBtn}
                header={"QR"}
                sortable={false}
              />
              <Column
                style={{width: '60px', textAlign: "center"}}
                key="delete"
                field="delete"
                body={deleteQrCodeBtn}
                sortable={false}
              />

            </DataTable>
          )}
        </TableContainer>
      </PageConstraint>
    </>
  );
};

export default QrCodesPage;
