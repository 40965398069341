import React, { useEffect, useRef } from 'react';
import QRCode from 'davidshimjs-qrcodejs';
import styled from 'styled-components'

const QrCodeFigure = styled.figure`
  &&& {
    width: 240px;
    height: 240px;
    margin: 30px auto 30px;
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
  }
`;

const QrCodeGenerator = ({ url }) => {
  const qrRef = useRef(null);
  useEffect(() => {
    // ?
    qrRef.current.innerHTML = '';
    new QRCode(qrRef.current, {
      text: url,
      width: 600,
      height: 600,
    });
  }, [url]);

  return (
    <>
      <QrCodeFigure id="qr-code" ref={qrRef}></QrCodeFigure>
    </>
  );
};

export default QrCodeGenerator;
