import React, { useState, useEffect } from 'react'
import QrCodeGenerator from "./components/QrCodeGenerator";
import styled, { StyleSheetManager } from 'styled-components';

const Main = styled.div`
  &&& {
    font-family: "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", -apple-system, BlinkMacSystemFont, sans-serif;

    h1 {
      font-size: 36px;
      line-height: 42px;
      text-align: center;
      margin: 60px 0 80px;
    }

    button {
      padding: 6px 14px;
      border-radius: 40px;
      background: #3bb377;
      border: none;

      position: absolute;
      right: 30px;
      top: 30px;

      color: #FFF;
      font-size: 14px;
      line-height: 20px;
    }

    @media print {
      .no-print {
        display: none !important;
      }
    }
  }
`

const QrCodesPrintablePage = (data) => {
  const [getWindow] = useState(data.window ?? window);

  /**
   * Will automatically try to print QR-code
   */
  useEffect(() => {
    if (getWindow) {
      handlePrint();
    }
  }, [getWindow]);

  /**
   * Trigger print
   * Notice: You can change which window to print
   */
  const handlePrint = () => {
    setTimeout(function() {
      getWindow.focus();
      getWindow.print();
    }, 50);
  };

  return (
    <>
      <StyleSheetManager target={getWindow.document.head}>
        <Main>
          <button className="no-print" onClick={handlePrint}>{data.buttonTitle ?? "Print"}</button>
          <h1>{(data.title && data.title.length) > 0 ? data.title : String.fromCharCode(160)}</h1>
          <QrCodeGenerator url={data.url}/>
        </Main>
      </StyleSheetManager>
    </>
  );
}

export default QrCodesPrintablePage;
