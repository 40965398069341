import React from 'react'
import { useIntl } from 'react-intl'
import ReactDOM from 'react-dom';
import QrCodeGenerator from './QrCodeGenerator';
import QrCodesPrintablePage from '../QrCodesPrintablePage';
import { Button } from "Components/input";
import styled from 'styled-components';

const Aside = styled.aside`
  &&& {
    display: flex;
    align-items: center;
  }
  #button-print {
    margin-right: auto;
  }
`

const ReadQrModal = ({modalController, modalData}) => {
  const intl = useIntl()
  const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
  const qrUrl = (publicUrl.origin ?? publicUrl.href) + '/user-area/qr/' + modalData.id;

  /**
   * Store/update QR code from the active select choices
   * @returns {Promise<void>}
   */
  const handlePrint = () => {
    const title = (modalData.location ?? "");
    const newWindow = window.open('', '', 'width=800,height=600');
    newWindow.document.write('<div id="printable"></div>');
    newWindow.document.close();
    ReactDOM.render(<QrCodesPrintablePage
        title={title} url={qrUrl}
        window={newWindow}
        buttonTitle={intl.formatMessage({id: 'common.print'})}/>,
      newWindow.document.getElementById('printable'));
  };

  return (
    <>
      <Aside>
        <Button id="button-print" primary label={intl.formatMessage({id: 'common.print'})} onClick={handlePrint}/>
        <a href={qrUrl} target="_blank" rel="noopener noreferrer">
          <Button icon="pi pi-external-link" label={intl.formatMessage({id: 'common.visit'})} />
        </a>
      </Aside>
      <QrCodeGenerator url={qrUrl}/>
    </>
  );
};

export default ReadQrModal;
